import { AnalyticsService, injectionKey } from "./analytics.service.js"

export const AnalyticsPlugin = {
  install(app, { router, store, trackPageViews, extraProperties } = {}) {
    const analyticsService = new AnalyticsService()
    extraProperties && analyticsService.setGlobalProperties(extraProperties)

    // Attach to vuex and provide in app
    store && (store[injectionKey] = analyticsService)
    app.provide(injectionKey, analyticsService)
    Object.defineProperty(app.config.globalProperties, injectionKey, {
      get: () => analyticsService
    })

    // Setup page-view tracking
    if (trackPageViews) {
      router.afterEach((to) => {
        ;(async () => {
          await analyticsService.pageTracking.whenStarted
          analyticsService.trackPageView({
            fullPath: to.fullPath,
            routeName: to.name || (to.fullPath === "/" ? "Index" : to.name)
          })
        })()
      })
    }
  }
}
