import { createStore } from 'vuex'
import * as auth from './modules/auth'

export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {

  },
  modules: {
    auth
  }
})
